import requesthttps from '@/utils/requesthttps';
//下载题集拓思德的文件
export function getDownloadFileTuoSiDe(data) {
  return requesthttps({
    url: '/resourcePlant-web/api/console/tsdCode/downloadFile',
    method: 'post',
    data,
    responseType: 'blob',
  });
}
//下载棒棒帮铺码文件
export function getDownloadFile(data) {
  return requesthttps({
    url: '/resourcePlant-web/api/console/bbbCode/downloadFile',
    method: 'post',
    data,
    responseType: 'blob',
  });
}
export function queryUserListByClassId(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/class/queryUserListByClassId',
    method: 'post',
    data,
  });
}
export function queryAllErrorBookList(data) {
  return requesthttps({
    url: '/resourcePlant-web/api/console/errorBook/queryAllErrorBookList',
    method: 'post',
    data,
  });
}
export function createErrorBookSet(data) {
  return requesthttps({
    url: '/resourcePlant-web/api/console/errorBook/createErrorBookSet',
    method: 'post',
    data,
  });
}
export function save(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/save',
    method: 'post',
    data,
  });
}
export function queryUserPlanList(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/queryUserPlanList',
    method: 'post',
    data,
  });
}
//当前题信息
export function queryDetailById(data) {
  return requesthttps({
    url: '/resourcePlant-web/api/console/school_questionSet/queryDetailById',
    method: 'post',
    data,
  });
}
export function queryRelationIdsByPlanId(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/queryRelationIdsByPlanId',
    method: 'post',
    data,
  });
}
export function queryErrorListByStudentCatalogTactics(data) {
  return requesthttps({
    url:
      '/resourcePlant-web/api/console/errorBook/queryErrorListByStudentCatalogTactics',
    method: 'post',
    data,
  });
}
