import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home/index.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'test',
  //   component: () => import('@/components/Ceshi/testCahatgpt.vue'),
  // },
  // {
  //   path: '/Local2',
  //   name: 'Local2',
  //   component: () => import('../views/Local2.vue'),
  // },
  {
    path: '/Local',
    name: 'Local',
    component: () => import('../views/Local.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/Class',
    children: [
      {
        path: '/Class',
        name: 'Class',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Middle" */ '../views/Middle/index.vue'),
        meta: {
          title: '课前',
          icon: 'form',
        },
        redirect: '/Class',
        children: [
          {
            path: '/Class',
            name: 'Class',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Class" */
                '../views/Class/index.vue'
              ),
          },
          {
            path: '/explain',
            name: 'Explain',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Explain" */
                '../views/Explain/index.vue'
              ),
            meta: {
              title: '讲解',
              icon: 'form',
            },
          },
          {
            path: '/study',
            name: 'Study',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Study" */
                '../views/Study/index.vue'
              ),
            meta: {
              title: '总览',
              icon: 'form',
            },
          },
          {
            path: '/correct',
            name: 'Correct',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Correct" */
                '../views/Correct/index.vue'
              ),
            meta: {
              title: '批改',
              icon: 'form',
            },
          },
          {
            path: '/ModifyTheTitle',
            name: 'ModifyTheTitle',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "ModifyTheTitle" */
                '../views/ModifyTheTitle/index.vue'
              ),
            meta: {
              title: '修改',
              icon: 'form',
            },
          },
        ],
      },
      {
        path: '/InClass',
        name: 'InClass',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Middle" */ '../views/Middle/index.vue'),
        meta: {
          title: '课中',
          icon: 'form',
        },
        redirect: '/InClass',
        children: [
          {
            path: '/InClass',
            name: 'InClass',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "InClass" */
                '../views/InClass/index.vue'
              ),
          },
          {
            path: '/explain1',
            name: 'Explain1',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Explain" */
                '../views/Explain/index.vue'
              ),
            meta: {
              title: '讲解',
              icon: 'form',
            },
          },
          {
            path: '/study1',
            name: 'Study1',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Study" */
                '../views/Study/index.vue'
              ),
            meta: {
              title: '总览',
              icon: 'form',
            },
          },
          {
            path: '/correct1',
            name: 'Correct1',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Correct" */
                '../views/Correct/index.vue'
              ),
            meta: {
              title: '批改',
              icon: 'form',
            },
          },
          {
            path: '/ModifyTheTitle1',
            name: 'ModifyTheTitle1',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "ModifyTheTitle" */
                '../views/ModifyTheTitle/index.vue'
              ),
            meta: {
              title: '修改',
              icon: 'form',
            },
          },
        ],
      },
      {
        path: '/afterClass',
        name: 'afterClass',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Middle" */ '../views/Middle/index.vue'),
        meta: {
          title: '课后',
          icon: 'form',
        },
        redirect: '/afterClass',
        children: [
          {
            path: '/afterClass',
            name: 'afterClass',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "afterClass" */
                '../views/afterClass/index.vue'
              ),
          },
          {
            path: '/explain2',
            name: 'Explain2',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Explain" */
                '../views/Explain/index.vue'
              ),
            meta: {
              title: '讲解',
              icon: 'form',
            },
          },
          {
            path: '/study2',
            name: 'Study2',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Study" */
                '../views/Study/index.vue'
              ),
            meta: {
              title: '总览',
              icon: 'form',
            },
          },
          {
            path: '/correct2',
            name: 'Correct2',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Correct" */
                '../views/Correct/index.vue'
              ),
            meta: {
              title: '批改',
              icon: 'form',
            },
          },
          {
            path: '/ModifyTheTitle2',
            name: 'ModifyTheTitle2',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "ModifyTheTitle" */
                '../views/ModifyTheTitle/index.vue'
              ),
            meta: {
              title: '修改',
              icon: 'form',
            },
          },
        ],
      },
      {
        path: '/source',
        name: 'Source',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Source" */ '../views/Source/index.vue'),
        meta: {
          title: '资源',
          icon: 'form',
        },
      },
      {
        path: '/test',
        name: 'Test',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Middle" */ '../views/Middle/index.vue'),
        meta: {
          title: '测验',
          icon: 'form',
        },
        redirect: '/Test',
        children: [
          {
            path: '/Test',
            name: 'Test',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "Test" */ '../views/Test/index.vue'),
          },
          {
            path: '/explain3',
            name: 'Explain3',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Explain" */
                '../views/Explain/index.vue'
              ),
            meta: {
              title: '讲解',
              icon: 'form',
            },
          },
          {
            path: '/study3',
            name: 'Study3',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Study" */
                '../views/Study/index.vue'
              ),
            meta: {
              title: '总览',
              icon: 'form',
            },
          },
          {
            path: '/correct3',
            name: 'Correct3',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Correct" */
                '../views/Correct/index.vue'
              ),
            meta: {
              title: '批改',
              icon: 'form',
            },
          },
          {
            path: '/ModifyTheTitle3',
            name: 'ModifyTheTitle3',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "ModifyTheTitle" */
                '../views/ModifyTheTitle/index.vue'
              ),
            meta: {
              title: '修改',
              icon: 'form',
            },
          },
        ],
      },
      {
        path: '/collect',
        name: 'Collect',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Collect" */ '../views/Collect/index.vue'
          ),
        meta: {
          title: '错题',
          icon: 'form',
        },
      },
      {
        path: '/explains',
        name: 'Explains',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Explains" */ '../views/Explains/index.vue'
          ),
        meta: {
          title: '讲解',
          icon: 'form',
        },
      },
      {
        path: '/userStudy',
        name: 'UserStudy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "UserStudy" */ '../views/UserStudy/index.vue'
          ),
        meta: {
          title: ' 总览',
          icon: 'form',
        },
      },
      {
        path: '/strategy',
        name: 'Strategy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Strategy" */ '../views/Strategy/index.vue'
          ),
        meta: {
          title: '策略',
          icon: 'form',
        },
      },
      {
        path: '/notebook',
        name: 'Notebook',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Middle" */ '../views/Middle/index.vue'),
        meta: {
          title: '策略',
          icon: 'form',
        },
        redirect: '/notebook',
        children: [
          {
            path: '/Notebook',
            name: 'Notebook',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Notebook" */
                '../views/Notebook/index.vue'
              ),
          },
          {
            path: '/handwriting',
            name: 'Handwriting',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "Handwriting" */
                '../views/Handwriting/index.vue'
              ),
            meta: {
              title: '笔迹',
              icon: 'form',
            },
          },
        ],
      },
      {
        path: '/microClass',
        name: 'MicroClass',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Middle" */ '../views/Middle/index.vue'),
        meta: {
          title: '微课',
          icon: 'form',
        },
        redirect: '/MicroClass',
        children: [
          {
            path: '/MicroClass',
            name: 'MicroClass',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "MicroClass" */
                '../views/MicroClass/index.vue'
              ),
          },
          {
            path: '/explainMicro',
            name: 'ExplainMicro',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "ExplainMicro" */
                '../views/ExplainMicro/index.vue'
              ),
            meta: {
              title: '详情',
              icon: 'form',
            },
          },
        ],
      },
      {
        path: '/handwriting',
        name: 'Handwriting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Handwriting" */
            '../views/Handwriting/index.vue'
          ),
        meta: {
          title: '笔迹',
          icon: 'form',
        },
      },
      {
        path: '/handwritings',
        name: 'Handwritings',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Handwriting" */
            '../views/Handwritings/index.vue'
          ),
        meta: {
          title: '笔迹',
          icon: 'form',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/rem',
    name: 'Rem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Rem" */ '../views/Rem/index.vue'),
    meta: {
      title: '批改',
      icon: 'form',
    },
  },
  {
    path: '/handwritingsRem',
    name: 'HandwritingsRem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "HandwritingsRem" */ '../views/HandwritingsRem/index.vue'
      ),
    meta: {
      title: '笔记',
      icon: 'form',
    },
  },
  {
    path: '/VideoPlayback',
    name: 'VideoPlayback',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "VideoPlayback" */ '../views/VideoPlayback/index.vue'
      ),
    meta: {
      title: '播放',
      icon: 'form',
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: '/teach',
  routes,
});
// 路由守卫
router.beforeEach(async (to, from, next) => {
  if (to.path == '/login') {
    next();
  } else if (
    to.path == '/Rem' ||
    to.path == '/rem' ||
    to.path == '/HandwritingsRem' ||
    to.path == '/handwritingsRem'
  ) {
    next();
  } else {
    if (localStorage.getItem('teachuserToken')) {
      next();
    } else {
      next('/login');
    }
  }
});
export default router;
