<template>
	<el-dialog :visible.sync="getUpScoreShow" width="30%">
		<div class="displayFlex justifyContentSpaceAround">
			<span>设置分数等级：</span>
			<div class="displayFlex alignItemsCenter">
				<span>0< </span>
				<span class="red spanbase"></span>
				<span>≤ </span>
				<el-input style="width:80px;" v-model="getUpScore1" /> 	
				<span>< </span>
				<span class="yellow spanbase"></span>
				<span>≤  </span>
				<el-input style="width:80px;" v-model="getUpScore2" />	
				<span>< </span> 
				<span class="green spanbase"></span>
				<span>≤ 100%</span>
			</div>
		</div>
		<div class="displayFlex justifyContentSpaceAround">
			<span>设置重绘速率：</span>
			<div class="displayFlex alignItemsCenter">
				<el-input-number v-model="num" :step="20"></el-input-number>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="getUpScoreShow = false">取 消</el-button>
			<el-button type="primary" @click="getUpScore()">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { mapMutations} from 'vuex'
	import baseJson from '../assets/json/base.json'
	import {
		levelSettingSave,levelSettingQueryList
	} from '../api/base.js'
	export default {
		name: 'Study',
		data() {
			return {
				num:210,
				jige: '',
				levelSetting:{
					currPage:1,
					pageSize:10,
				},
				greaterThan: [{
						"name": "优秀",
						"value": 80
					},
					{
						"name": "及格",
						"value": 60
					}
				],
				getUpScore1:null,
				getUpScore2:null,
				getUpScoreShow: false
			}
		},
		create() {},
		mounted() {
			this.greaterThan = this.$store.state.greaterThan
			this.getUpScore1=this.greaterThan[1].value+'%'
			this.getUpScore2=this.greaterThan[0].value+'%'
		},
		methods: {
			init() {
				this.greaterThan = this.$store.state.greaterThan
				this.getUpScore1=this.greaterThan[1].value+'%'
				this.getUpScore2=this.greaterThan[0].value+'%'
				this.LevelSettingQueryList()
				this.getUpScoreShow = true
			},
			getUpScore(){
				let getLevelsArr =[this.getUpScore1,this.getUpScore2]
				this.getLevels(getLevelsArr)
				this.getDotDrawSpeed(this.num)
				this.getUpScoreShow=false
				this.$message("设置成功")
				let data = {
					 "classId": JSON.parse(localStorage.getItem('teachclass_id')),
					 "subjectId": JSON.parse(localStorage.getItem('teachsubjectId')),
					 "level": 1,
				}
				for(let i=0;i<3;i++){
					let canshu=JSON.parse(JSON.stringify(data))
					if(i==0){
						canshu.code="good"
						canshu.endScoe= 100
						canshu.name= "优秀"
						canshu.serial= 1
						canshu=this.ping(canshu)
						canshu.startScore= this.getUpScore2.slice(0,this.getUpScore2.length-1)
					}else if(i==1){
						canshu.code="pass"
						canshu.name= "及格"
						canshu.serial= 2
						canshu=this.ping(canshu)
						canshu.endScoe= this.getUpScore2.slice(0,this.getUpScore2.length-1)
						canshu.startScore= this.getUpScore1.slice(0,this.getUpScore2.length-1)
					}else if(i==2){
						canshu.code="poor"
						canshu.name= "差"
						canshu.serial= 3
						canshu=this.ping(canshu)
						canshu.endScoe= this.getUpScore1.slice(0,this.getUpScore2.length-1)
						canshu.startScore= 0
					}
					this.levelSettingSave(canshu)
				}
			},
			// 匹配
			ping(canshu){
				let data;
				if(this.levelSetting&&this.levelSetting.list&&this.levelSetting.list.length>0){
					this.levelSetting.list.forEach(item=>{
						if(canshu.code==item.code){
							data=item
						}
					})
				}
				return data?data:canshu
			},
			// 保存颜色等级
			levelSettingSave(data) {
				levelSettingSave(data).then(res => {
					
				})
			},
			// 查询颜色等级
			LevelSettingQueryList(){
				let data={
					currPage:this.levelSetting.currPage,
					pageSize:this.levelSetting.pageSize,
					"classId": JSON.parse(localStorage.getItem('teachclass_id')),
					"subjectId": JSON.parse(localStorage.getItem('teachsubjectId')),
				}
				levelSettingQueryList(data).then(res=>{
					this.levelSetting=res.data.result
					if(this.levelSetting.list&&this.levelSetting.list.length>0)
					this.levelSetting.list.forEach(item=>{
						if(item.code=='good'){
							this.getUpScore2=item.startScore+'%'
						}
						if(item.code=='pass'){
							this.getUpScore1=item.startScore+'%'
						}
					})
				})
			},
			...mapMutations(["getLevels","getDotDrawSpeed"])
		}
	}
</script>

<style lang="scss">
	.spanbase {
		width: 11px;
		height: 12px;
		display: inline-block;
		margin: 0 10px;
	}
</style>
