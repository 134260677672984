import {
  queryDetailById,
  getDownloadFileTuoSiDe,
  getDownloadFile,
} from '../api/Collect.js';
import baseFun from './base/base.js';
import { Message } from 'element-ui';
const downloads = (codeType, id) => {
  // debugger;
  let data = {
    setId: id,
  };
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  queryDetailById(formData).then((response) => {
    if (response.data.result.codeUrl && response.data.result.codeUrl != '') {
      if (codeType == '1') {
        baseFun.DownloadProcessing(response.data.result.codeUrl);
      } else if (codeType == '2') {
        //下载棒棒帮的文件
        let data = {
          pdfId: response.data.result.codeUrl,
        };
        getDownloadFile(data).then((res) => {
          if (res.data && res.data != '') {
            DownloadDataStream(res.data, fileName);
          } else if (names == '重新铺码') {
            this.Paving = true;
          } else {
            this.$message.error('铺码失败,可能有特殊字符');
          }
        });
      } else {
        //下载拓思德的文件
        let data = {
          pdfId: response.data.result.codeUrl,
        };
        getDownloadFileTuoSiDe(data).then((res) => {
          console.log('res', res);
          if (res.data && res.data != '') {
            DownloadDataStream(res.data, fileName);
          } else if (names == '重新铺码') {
            this.Paving = true;
          } else {
            this.$message.error('铺码失败,可能有特殊字符');
          }
        });
      }

      // baseFun.DownloadProcessing(response.data.result.codeUrl);

      // debugger;
    } else {
      Message.error('铺码失败,可能有特殊字符');
    }
  });
};
//下载处理数据流
function DownloadDataStream(res, fileName) {
  // console.log(res);
  let blob = new Blob([res], {
    type: 'application/pdf;charset=UTF-8;',
  }); // 这里的res为后端返回的流数据
  console.log('blob', blob);
  let aLink = document.createElement('a');
  // let name = new Date().getTime()
  let name = fileName;
  aLink.download = name + '.pdf'; // 下载文件的名字
  console.log('最后：', URL.createObjectURL(blob));
  aLink.href = URL.createObjectURL(blob);
  aLink.click();
}
function getQuestionSet(data, type) {
  let canshu;
  data.forEach((item) => {
    if (item.relationKind == type) {
      canshu = {
        relationId: item.relationId,
        codeType: item.codeType,
      };
    }
  });
  return canshu;
}
export default {
  downloads,
  getQuestionSet,
};
