import axios from 'axios'
import {  Message } from 'element-ui'
import HOSTURL from './https.js'
import baseJs from './base/base.js'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
const service = axios.create({
  baseURL: HOSTURL, // url = base url + request url
})
window.axiosCancel = []
service.interceptors.request.use(
  config => {
    if (JSON.parse(localStorage.getItem("teachuserToken"))) {
      config.headers['userToken'] = JSON.parse(localStorage.getItem("teachuserToken"))
    }else{
      Message({
        message: "token失效，请重新登录",
        type: 'error',
        duration: 5 * 1000
      })
    }
    config.cancelToken = new axios.CancelToken(cancel => {
      window.axiosCancel.push({cancel})
    })
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
	let data=baseJs.SignIn({loginData:response,token:'teachuserToken'})
	return data
  },
  error => {
    if(error.message!='取消了请求'){
      Message({
        message: "暂无数据",
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
