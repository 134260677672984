<template>
  <div class="Left_nav">
    <div style="height: 60px;">
      <img style="width: 28px;" src="../assets/img/02/logo2.png" />
    </div>
    <div
      v-for="(item, index) in items"
      :key="item.message"
      @click="router_get(item.url, index)"
      :style="{ backgroundColor: li_index == index ? '#092F7A' : '' }"
    >
      <div>
        <img :src="item.img" />
        <div style="margin-top: 10px;">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMenu } from '../api/base.js';
export default {
  name: 'Study',
  data() {
    return {
      base_url: 'http://8.133.164.60:5000/',
      items: [],
      li_index: localStorage.getItem('teachLi_index')
        ? localStorage.getItem('teachLi_index')
        : 0,
    };
  },
  create() {},
  mounted() {
    this.getmenu();
  },
  methods: {
    router_index() {
      this.items.forEach((item, index) => {
        if (item.message == '/Study') {
          this.li_index = index;
          localStorage.setItem('teachLi_index', index);
        }
      });
    },
    router_get(e, index) {
      this.li_index = index + '';
      localStorage.setItem('teachLi_index', index);
      this.$router.push({
        path: e,
      });
    },
    getmenu() {
      getMenu({
        // fromId: JSON.parse(localStorage.getItem('teachlogin')).corpId,
        fromId: 1000146,
        fromKind: 'menu',
      }).then((response) => {
        //微课隐藏
        let res = response.data.result.filter((item) => {
          return (
            item.name == '课前' ||
            item.name == '课中' ||
            item.name == '课后' ||
            item.name == '学情'
          );
        });
        res[3].name = '总览';
        this.items = res;
        // this.items =response.data.result
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style>
.Left_nav {
  color: #ffffff;
}

.Left_nav > div {
  height: 80px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #deebff;
}
</style>
