import Vue from 'vue';
import {
  Header,
  Table,
  TableColumn,
  Select,
  Option,
  Collapse,
  CollapseItem,
  Button,
  ButtonGroup,
  Dialog,
  Form,
  FormItem,
  Tree,
  Drawer,
  Input,
  InputNumber,
  DatePicker,
  Popover,
  RadioGroup,
  RadioButton,
  Container,
  Aside,
  Main,
  Progress,
  Pagination,
  Breadcrumb,
  Slider,
  Tabs,
  TabPane,
  BreadcrumbItem,
  Rate,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Upload, //很特殊
  Radio,
  OptionGroup,
  Image,
  Message,
  Tag,
  Empty,
  Loading,
  Notification,
} from 'element-ui';
Vue.use(Loading);
Vue.use(Empty);
Vue.use(Tag);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(Popover);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tree);
Vue.use(Slider);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Rate);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Image);
Vue.use(Drawer);

Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
//  function(msg) {
// return Message({
//   message: msg,
//   duration: 1500
// });
// };
