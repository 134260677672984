import request from '@/utils/request'
import requesthttps from '@/utils/requesthttps'
import request2 from '@/utils/request2'
export function getChatAis(data) {
    return requesthttps({
        url: '/chatAi/chatAi',
        method: 'post',
        data
    })
}
export function getTextBook(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/class/queryTextBookInfoListByClassId',
        method: 'post',
        data
    })
}
export function uploadFile(data) {
    return requesthttps({
        url: '/epen-slice-upload/api/console/oss/uploadFile',
        method: 'post',
        data
    })
}
export function getMenu(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/module/queryListByFromId',
        method: 'post',
        data
    })
}
export function changeLanguage(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/user/changeLanguage',
        method: 'post',
        data
    })
}
// 查询用户绑定的笔
export function queryMacList(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/pen/queryMacList',
        method: 'post',
        data
    })
}
export function tutorSave(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/tutor/save',
        method: 'post',
        data
    })
}
export function getStudentsByClassId(data) {
    return request({
        url: '/get_students_by_classId',
        method: 'post',
        data
    })
}
export function getChoiceResultByQuestionId(data) {
    return request({
        url: '/get_choice_result_by_questionId',
        method: 'post',
        data
    })
}
export function getClassDots(data) {
    return request({
        url: '/get_class_dots',
        method: 'post',
        data
    })
}
export function catalogsave(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/catalog/save',
        method: 'post',
        data
    })
}
export function catalogdelete(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/catalog/delete',
        method: 'post',
        data
    })
}
export function labelsave(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/label/save',
        method: 'post',
        data
    })
}

export function updateBaseInfo(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/updateBaseInfo',
        method: 'post',
        data
    })
}
// 获取班级列表信息
export function queryListByDictValue(data) {
    return request2({
        url: '/userPlant-web/api/console/dict/queryListByDictValue',
        method: 'post',
        data
    })
}
export function bindMatchRelation(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/catalog/bindMatchRelation',
        method: 'post',
        data
    })
}
export function queryClassList(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/queryClassList',
        method: 'post',
        data
    })
}
//知识点
export function getKnowledge(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/school_catalog/queryRelationList',
        method: 'post',
        data
    })
}
export function thinkqueryList(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/label/queryList',
        method: 'post',
        data
    })
}
export function saveLabelRelation(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/question/saveLabelRelation',
        method: 'post',
        data
    })
}

export function queryPlanUserMaxRecords(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/queryPlanUserMaxRecords',
        method: 'post',
        data
    })
}
export function submitPenParamsToKafka(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/submitPenParamsToKafka',
        method: 'post',
        data
    })
}
export function questionSaveBase(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/question/saveBase',
        method: 'post',
        data
    })
}
export function queryPlanUserAutoJoinRecords(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/queryPlanUserAutoJoinRecords',
        method: 'post',
        data
    })
}
export function localConvertPdf(data) {
    return requesthttps({
        url: '/epen-slice-upload/api/console/local/convertPdf',
        method: 'post',
        data
    })
}
export function resourceSave(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/resource/save',
        method: 'post',
        data
    })
}
export function resourceQueryList(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/resource/queryList',
        method: 'post',
        data
    })
}
export function queryTimeList(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/termTimes/queryTimeList',
        method: 'post',
        data
    })
}
export function classQueryList(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/class/queryList',
        method: 'post',
        data
    })
}
export function questionSetQueryList(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/questionSet/queryList',
        method: 'post',
        data
    })
}
export function roomPlanSave(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/room_plan/save',
        method: 'post',
        data
    })
}
export function queryUserListByClassId(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/class/queryUserListByClassId',
        method: 'post',
        data
    })
}
export function getPageWorks(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/getPageWorks',
        method: 'post',
        data
    })
}
export function autoCorrectForDotEventsv1(data) {
    return requesthttps({
        url: '/auto-correct-out/auto_correct_for_dot_eventsv1',
        method: 'post',
        data
    })
}
export function gotUserSetPointsData(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/planUserResult/gotUserSetPointsData',
        method: 'post',
        data
    })
}
export function downLoadPointPdf(data) {
    return requesthttps({
        url: '/epen-slice-upload/api/console/local/downLoadPointPdf',
        method: 'post',
        data
    })
}
export function videoConvert(data) {
    return requesthttps({
        url: '/epen-slice-upload/api/console/oss/videoConvert',
        method: 'post',
        data
    })
}
//
export function shareSave(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/share/save',
        method: 'post',
        data
    })
}
//我的班级列表
export function queryMyClassList(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/class/queryMyClassList',
        method: 'post',
        data
    })
}
//查看计划详情  // 根据planId 获取教学章节ID
export function queryPlanById(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/queryPlanById',
        method: 'post',
        data
    })
}
// 查看视频列表
export function tutorQueryList(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/tutor/queryPageList',
        method: 'post',
        data
    })
}
// 批量获取學生笔迹数据
export function gotUserBatchSetPointsData(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/planUserResult/gotUserBatchSetPointsData',
        method: 'post',
        data
    })
}
// 保存作业
export function tasksave(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/save',
        method: 'post',
        data
    })
}
// 正在批改谁？
export function queryCorrectUserIdByMac(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/pen/queryCorrectUserIdByMac',
        method: 'post',
        data
    })
}
export function baseQueryDetailById(data) {
    return request2({
        url: '/resourcePlant-web/api/console/questionSet/queryDetailById',
        method: 'post',
        data
    })
}
// 识别异常myscriptSave
export function myscriptSave(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/myscript/save',
        method: 'post',
        data
    })
}
// 意见反馈接口
export function suggestSave(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/suggest/save',
        method: 'post',
        data
    })
}
// 保存分数等级设置
export function levelSettingSave(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/levelSetting/save',
        method: 'post',
        data
    })
}
// 所有分数等级设置列表
export function levelSettingQueryList(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/levelSetting/queryList',
        method: 'post',
        data
    })
}
// 教材列表
export function queryEditionListByClassId(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/class/queryEditionListByClassId',
        method: 'post',
        data
    })
}
// 查询题集通过率
export function queryPlanUserQuestionFetureStations(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/queryPlanUserQuestionFetureStations',
        method: 'post',
        data
    })
}
// 提交状态：5：已查看状态 | 6：已批改状态
export function submitCheckStatus(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/planUserResult/submitCheckStatus',
        method: 'post',
        data
    })
}
// 错题进行一键重批
export function queryPlanUserAutoErrorMaxRecords(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/plan/queryPlanUserAutoErrorMaxRecords',
        method: 'post',
        data
    })
}
// 根据班级id 作业Id查询班级下用户的批改状态
export function gotPlanJoinResultList(data) {
    return requesthttps({
        url: '/userPlant-web/api/console/planUserResult/gotPlanJoinResultList',
        method: 'post',
        data
    })
}
// 按章节下-题集掌握程度分层出题
export function queryQuestionListBySetDegreeTactics(data) {
    return requesthttps({
        url: '/resourcePlant-web/api/console/questionAi/queryQuestionListBySetDegreeTactics ',
        method: 'post',
        data
    })
}

