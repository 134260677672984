import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ElementUI from 'element-ui';
// import {
// 	Message
// } from 'element-ui';
import './plugins'
import 'element-ui/lib/theme-chalk/index.css';
import Axios from 'axios'
import HOSTURL from './utils/host.js'
import apis from './utils/https.js'
import Router from 'vue-router';
import Download from './utils/Download.js'
import baseFun from './utils/base/base.js'
// 引入sweiper
import AlloyFinger from 'alloyfinger'
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'
import globalVariable from './utils/globalVariable'
//不用全局 要按需加载
// import * as echarts from 'echarts'
import moment from 'moment' //导入文件
import VueQuillEditor from "vue-quill-editor"
// require styles 引入样式
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import eldragdialog from "./utils/directive";
Vue.use(VueQuillEditor)

//引入全局变量 老师批改数据
Vue.prototype.$recorretedDatas = {};

Vue.prototype.$moment = moment; //赋值使用
// Vue.prototype.$echarts = echarts
Vue.prototype.commonsVariable = globalVariable
Vue.use(AlloyFingerPlugin, {
	AlloyFinger
})
Vue.prototype.$HOSTURL = HOSTURL;
Vue.prototype.$Download = Download;
Vue.prototype.$baseFun = baseFun;
Vue.prototype.$axios = Axios
Vue.prototype.$apis = apis;
// Vue.prototype.$message = function(msg) {
//   return Message({
//     message: msg,
//     duration: 1500
//   });
// };
// Vue.user(ElementUI)
Vue.use(Router);
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false
Vue.directive('preventReClick', {
	inserted(el, binding) {
		el.addEventListener('click', () => {
			if (!el.disabled) {
				el.disabled = true
				setTimeout(() => {
					el.disabled = false
				}, binding.value || 2000)
			}
		})
	}
});
Vue.directive('eldragdialog',eldragdialog);
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
