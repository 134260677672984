<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	import "quill/dist/quill.core.css";
	import "quill/dist/quill.snow.css";
	import "quill/dist/quill.bubble.css";
	import './assets/css/base.css'
	import teachlanguagechian from './assets/json/chian.json'
	export default {
		name: 'App',
		data() {
			return {
				to: ''
			}
		},
		onshow() {
			localStorage.setItem("teachlanguage", JSON.stringify(val))
			this.$store.commit('teachlanguagedatashow', teachlanguagechian)
		},
		watch: {
			$route(to, from) {
				this.to = to.path
				if (from.path == '/explain' || from.path == '/Explain'||from.path == '/explain1' || from.path == '/Explain1'||from.path == '/explain2' || from.path == '/Explain2'||from.path == '/explain3' || from.path == '/Explain3') {
					localStorage.removeItem('teachperson_stu_id')
				} else if (to.path != '/study' || to.path == '/Study') {
					localStorage.removeItem('teachStudy_stu_id')
				} else if (to.path == '/explain' || to.path == '/Explain'||to.path == '/explain1' || to.path == '/Explain1'||to.path == '/explain2' || to.path == '/Explain2'||to.path == '/explain3' || to.path == '/Explain3') {
					localStorage.removeItem('teachquestion_id')
				}
			}
		},
	}
</script>
<style lang="scss">
	@import url("./assets/css/main.css");
	.red{
		background-color: #FF6666;
	}
	.green{
		background-color: #70F58B;
	}
	.yellow{
		background-color: #FAFA6B;
	}
	.buttonBase{
		background-color: #5D94E6;
		color: #fff;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// 修改canvas拖动注掉的
		text-align: center;
		color: #2c3e50;
		cursor: pointer;
		overflow: hidden;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.IntelligenceSheng {
		margin-top: 20px;
	}

	.IntelligenceShengspan {
		display: inline-block;
		height: 40px;
		background: #19409e;
		border-radius: 2px;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #fff;
		padding: 10px 20px;
		margin-left: 20px;
	}

	.my-info-dialog .el-dialog {
		margin-top: 2vh !important;
	}

	.studybutclass {
		background: #13264D !important;
		color: #FFFFFF;
	}

	.basebtn {
		padding: 0 24px;
		background: #D7DBE2;
		border-radius: 0px 2px 2px 0px;
		font-size: 14px;
		border: 0;
		margin-left: 2px;
		text-align: center;
	}

	.base_border_text {
		font-size: 20px;
		font-weight: 400;
		color: #13264D;
		display: inline-block;
		padding: 0 5px 0 8px;
		line-height: 20px;
		border-left: 6px solid #13264D;
	}
	.base_border_text_base {
		font-size: 20px;
		font-weight: 400;
		color: #13264D;
		display: inline-block;
		padding: 20px 5px 0 0px;
		line-height: 20px;
	}

	.study_top_con_span {
		width: 183px;
		border-radius: 1px;
		border: 1px solid #D8DEEA;
		margin-left: 30px;
		padding: 12px 9px;
		text-align: left;
		display: flex;
		flex-wrap: wrap;

		.study_top_con_span_div1 {
			font-size: 12px;
			font-weight: 400;
			color: #6E7C94;
			line-height: 22px;
		}

		.study_top_con_span_div2 {
			font-size: 32px;
			font-weight: 500;
			color: #071737;
			line-height: 45px;
			display: flex;
			justify-content: space-between;
			width: 100%;

			span {
				display: inline-block;
				padding-top: 4px;
			}
		}
	}

	.el-upload input {
		display: none !important;
	}
	.center{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
	.dot {
		overflow: auto;
	
		.dot_con {
			display: flex;
			flex-wrap: wrap;
		}
	
		.dot_bottm {
			position: absolute;
			right: 0;
			bottom: 0;
			margin-bottom: 10px;
			.dot_bottm_top {
				box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.3), 0px 8px 16px -4px rgba(9, 30, 66, 0.26);
				.dot_bottm_top_top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: #EDEFF2;
					height: 46px;
	
					.dot_bottm_top_top_left {
						display: flex;
						height: 46px;
						line-height: 46px;
	
						span {
							padding: 0 12px 0 16px;
							display: inline-block;
							font-size: 14px;
							color: #071737;
						}
					}
	
					.dot_bottm_top_top_right_clear {
						width: 52px;
						height: 32px;
						display: inline-block;
						line-height: 32px;
						margin-right: 37px;
						background: #FFFFFF;
						border-radius: 8px;
						border: 1px solid #5D94E6;
						color: #19409E;
						font-size: 14px;
					}
				}
	
				.dot_bottm_top_con {
					padding: 8px 0 10px;
					background: #fff;
	
					div {
						width: 668px;
						    text-align: left;
	
						span {
							height: 32px;
							line-height: 30px;
							display: inline-block;
							margin-left: 10px;
							text-align: left;
							white-space: nowrap;
							margin-top: 10px;
						}
					}
				}
			}
	
			.dot_bottm_bottm {
				float: right;
				div{
					margin-top: 12px;
					width: 100px;
					height: 32px;
					line-height: 32px;
					font-size: 14px;
					background: #FFFFFF;
					border-radius: 2px;
					border: 1px solid #D8DEEA;
					font-weight: 500;
					color: #19409E;
				}
			}
		}
	}
</style>
