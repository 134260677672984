<template>
</template>
<script>
	import {
		uploadFile,
		tutorSave,
		videoConvert
	} from '../../api/base.js'
	import Client from '../../utils/client.js'
	export default {
		name: 'App',
		data() {
			return {
			}
		},
		mounted() {
			this.init()
		},
		watch: {
			
		},
		methods: {
			init(){
				console.log(this.$store.state.dirHandle,"this.$store.state.dirHandle")
				if(this.$store.state.largeFile){
					if(this.$store.state.dirHandle){
						this.testDir(this.$store.state.dirHandle)
					}else{
						this.getLocalFile()
					}
				}
			},
			async getLocalFile() {
				const dirHandle = await window.showDirectoryPicker()
				this.$store.commit('getDirHandle', dirHandle)
			},
			async testDir(dirHandle) {
				this.dirHandle=dirHandle
			    for await (const entry of dirHandle.values()) {
			        if (entry.name.indexOf('xingzhengjin')!=-1) {
						this.fileName=entry.name
			            const fileHandle = await dirHandle.getFileHandle(entry.name);
			            const file = await fileHandle.getFile();
						const main=this.analysis(entry.name)
						await this.handleHttpRequest(file,main)
			        }
			    }
			},
			// 解析
			analysis(data){
				let canshu=data.split('_')
				let analysisDownload={
					mainName:canshu[1],
					mainId:canshu[2],
					mainKind:canshu[3],
					classId:canshu[4],
					fromId:canshu[5],
					subjectId:canshu[6],
					chapterId:canshu[7],
				}
				return analysisDownload
			},
			// 上传
			handleHttpRequest(videoFile,main) {
				let that = this;
				try {
					let fileName = this.fileName
					Client().multipartUpload(fileName, videoFile, {
							...this.options
						})
						.then((result) => {
							videoConvert({url:"https://teachers-explanation.oss-cn-shanghai.aliyuncs.com/" + fileName}).then(res=>{
								let data = {
									classId: main.classId,
									fileUrl:  res.data.data.url,
									mainId: main.mainId,
									mainKind:main.mainKind,
									mainName:main.mainName,
									// relationId:JSON.parse(localStorage.getItem('teachchaptersData')).id,
									// relationName:JSON.parse(localStorage.getItem('teachchaptersData')).name,
									// relationKind:'chapters',
									fromId:main.fromId,
									subjectId:main.subjectId,
									fileType:"https://teachers-explanation.oss-cn-shanghai.aliyuncs.com/" + fileName,
									chapterId:main.chapterId
								}
								tutorSave(data).then(res => {
									// this.$message({
									// 	message: '上传成功',
									// });
									this.dirHandle.removeEntry(fileName);
								})
							})
						});
				} catch (e) {}
			},
		}
	}
</script>
<style>
	
</style>
