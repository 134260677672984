<template>
  <div class="Top_nav">
    <div class="Top_nav_left">
      <img style="height: 30px;" :src="src" />
      <span class="logoText">云笔课堂</span>
      <div
        v-if="teachplanIdshow && teachusertype == 'remote_teacher'"
        style="margin-left: 40px;"
      >
        <span>{{ $store.state.teachlanguagedata.Selectclass }}：</span>
        <el-select
          clearable
          filterable
          v-model="teachclass"
          :placeholder="$store.state.teachlanguagedata.select"
          value-key="id"
        >
          <el-option
            v-for="item in teachclasss"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <crumbs></crumbs>
    </div>
    <div class="Top_nav_right">
      <language :LanguagesData="LanguagesData"></language>
      <el-dropdown>
        <div>
          <!-- <span style="margin-left: 24px;">HLY超级管理员</span> -->
          <span style="margin-left: 24px;">HLY超级管理员</span>
          <img style="margin-left: 8px;" src="../assets/img/02/男生头像.png" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="setUpScoreInt()"
            >设置分数等级</el-dropdown-item
          >
          <el-dropdown-item @click.native="opinionInt()"
            >意见反馈</el-dropdown-item
          >
          <el-dropdown-item @click.native="quit()">{{
            $store.state.teachlanguagedata.Logout
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <opinion ref="opinion"></opinion>
    <setUpScore ref="setUpScore"></setUpScore>
  </div>
</template>

<script>
import { queryClassList } from '../api/base.js';
import teachlanguagechian from '../assets/json/chian.json';
import teachlanguageenglish from '../assets/json/english.json';
import language from './language';
import opinion from './opinion/index.vue';
import setUpScore from './setUpScore.vue';
import crumbs from './crumbs/index.vue';
export default {
  name: 'Study',
  data() {
    return {
      setUpScoreShow: false,
      LanguagesData: '',
      // src:localStorage.getItem("teachlogo2Url")?JSON.parse(localStorage.getItem("teachlogo2Url")):require('../assets/img/02/logo.png'),
      src: require('../assets/img/02/logo3.png'),
      // src:require('../assets/img/02/logo.png'),
      teachusertype: localStorage.getItem('teachusertype'),
      teachplanIdshow: false,
      teachplanIdPlankind: localStorage.getItem('teachplanIdPlankind')
        ? JSON.parse(localStorage.getItem('teachplanIdPlankind'))
        : '',
      teachplanIdType: localStorage.getItem('teachplanIdType')
        ? JSON.parse(localStorage.getItem('teachplanIdType'))
        : '',
      teachplan_id: localStorage.getItem('teachplan_id')
        ? JSON.parse(localStorage.getItem('teachplan_id'))
        : '',
      username: JSON.parse(localStorage.getItem('teachusername')),
      subject_data: JSON.parse(localStorage.getItem('teachsubjects'))[0].name,
      subjects: JSON.parse(localStorage.getItem('teachsubjects')),
      teachclasss: '',
      teachclass: localStorage.getItem('teachclass')
        ? JSON.parse(localStorage.getItem('teachclass'))
        : '',
    };
  },
  components: {
    language,
    opinion,
    setUpScore,
    crumbs,
  },
  watch: {
    teachclass(val) {
      localStorage.setItem('teachclass', JSON.stringify(val));
      this.$store.state.teachclass = val;
    },
    username(val) {
      this.username = JSON.parse(localStorage.getItem('teachusername'));
    },
  },
  create() {
    // this.username = JSON.parse(localStorage.getItem('teachusername'));
  },
  mounted() {
    this.LanguagesData = JSON.parse(localStorage.getItem('teachuserID'));
    if (this.teachplanIdPlankind == '作业' && this.teachplanIdType == '课中') {
      this.teachplanIdshow = true;
      let data = {
        id: this.teachplan_id,
      };
      queryClassList(data).then((res) => {
        this.teachclasss = res.data.result;
        this.teachclasss.forEach((item) => {
          if (item.id == localStorage.getItem('teachclass_id')) {
            this.teachclass = item;
          }
        });
        localStorage.setItem(
          'teachteachclasss',
          JSON.stringify(this.teachclasss)
        );
      });
    }
  },
  methods: {
    setUpScoreInt() {
      this.$refs.setUpScore.init();
    },
    opinionInt() {
      this.$refs.opinion.init();
    },
    quit() {
      //清除本地缓存的讲解作业
      localStorage.removeItem('ClickHomeWork');
      if (localStorage.length > 0) {
        for (let i = localStorage.length - 1; i >= 0; i--) {
          if (localStorage.key(i).indexOf('teach') != -1) {
            localStorage.removeItem(localStorage.key(i));
          }
        }
      }
      this.$router.push('/Login');
    },
    check_subject_data(item) {
      this.subject_data = item.name;
    },
  },
};
</script>

<style>
.logoText {
  font-size: 24px;
  margin-left: 5px;
  font-weight: 700;
  color: rgb(25, 64, 158);
}
.Top_nav {
  height: 56px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  line-height: 56px;
  padding: 0 20px;
}

.Top_nav_right {
  display: flex;
  align-items: center;
}

.Top_nav_left {
  display: flex;
  align-items: center;
}
</style>
