import Vue from 'vue';
import Vuex from 'vuex';
import baseJson from '../assets/json/base.json';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    deleteSymbolShow: sessionStorage.getItem('deleteSymbolShow') || 'true', //删除符号是否开启
    teachplanIdTime: '',
    teach_val: '',
    teachclass: '',
    teachlanguagedata: '',
    videoStart: false,
    $recorretedDatas: {}, //批改结果
    dirHandle: null,
    pictureSize: null,
    screenStream: null,
    largeFile: false,
    greaterThan: JSON.parse(JSON.stringify(baseJson.greaterThan)),
    rollShow: true,
    nameSize: 2,
    whetherWrong: false,
    SubmitCheckStatuShow: false,
    current_page: sessionStorage.getItem('currentPage') || 0,
    arrDeptId: sessionStorage.getItem('ArrDeptId'),
    userStudyChapters: sessionStorage.getItem('UserStudyChapters') || [],
    StudentList: sessionStorage.getItem('StudentList') || [],
    levels: JSON.parse(sessionStorage.getItem('Levels')) || ['60%', '80%'], //保存分数等级,
    dotDrawSpeed: sessionStorage.getItem('DotDrawSpeed') || 210, //保存分数等级,
    clickHomeworkGetChapter: sessionStorage.getItem('ClickHomeworkGetChapter'),
    planData: JSON.parse(sessionStorage.getItem('PlanData')) || {}, //每次点击作业列表都会保存此题集的data
    dotsData: JSON.parse(sessionStorage.getItem('DotsData')) || [], //本地测试笔记录的点
  },
  mutations: {
    clearDotsData(state) {
      state.dotsData = [];
      sessionStorage.removeItem('DotsData');
    },
    getDotsData(state, v) {
      state.dotsData.push(v);
      sessionStorage.setItem('DotsData', JSON.stringify(state.dotsData));
    },
    getPlanData(state, n) {
      state.planData = n;
      sessionStorage.setItem('PlanData', JSON.stringify(n));
    },
    getClickHomeworkGetChapter(state, n) {
      state.clickHomeworkGetChapter = n;
      sessionStorage.setItem('ClickHomeworkGetChapter', n);
    },
    getLevels(state, n) {
      state.levels = n;
      sessionStorage.setItem('Levels', JSON.stringify(n));
    },
    //设置测试速率
    getDotDrawSpeed(state, n) {
      state.dotDrawSpeed = n;
      sessionStorage.setItem('DotDrawSpeed', n);
    },
    getStudentList(state, n) {
      state.StudentList = n;
      sessionStorage.setItem('StudentList', n);
    },
    getUserStudyChapters(state, n) {
      state.userStudyChapters = n;
      sessionStorage.setItem('UserStudyChapters', n);
    },
    getArrDeptId(state, n) {
      state.arrDeptId = n;
      sessionStorage.setItem('ArrDeptId', n);
    },
    getDeleteSymbolShow(state, n) {
      state.deleteSymbolShow = n;
      sessionStorage.setItem('deleteSymbolShow', JSON.stringify(n));
    },
    getCurrentPage(state, n) {
      state.current_page = n;
      sessionStorage.setItem('currentPage', n);
    },
    getSubmitCheckStatuShow(state, n) {
      state.SubmitCheckStatuShow = n;
    },
    getwhetherWrong(state, n) {
      state.whetherWrong = n;
    },
    show(state, n) {
      //自定义改变state初始值的方法，这里面的参数除了state之外还可以再传额外的参数(变量或对象);
      state.teachplanIdTime = n;
    },
    teachlanguagedatashow(state, n) {
      //自定义改变state初始值的方法，这里面的参数除了state之外还可以再传额外的参数(变量或对象);
      state.teachlanguagedata = JSON.parse(
        JSON.stringify(state.teachlanguagedata)
      );
      state.teachlanguagedata = null;
      state.teachlanguagedata = n;
    },
    getRecorretedDatas(state, n) {
      state.$recorretedDatas = n;
    },
    getPictureSize(state, n) {
      state.pictureSize = n;
    },
    getDirHandle(state, n) {
      state.dirHandle = n;
    },
    getScreenStream(state, n) {
      state.screenStream = n;
    },
    getGreaterThan(state, n) {
      state.greaterThan = n;
    },
    getLargeFile(state, n) {
      state.largeFile = n;
    },
    getRollShow(state, n) {
      state.rollShow = n;
    },
    getNameSize(state, n) {
      state.nameSize = n;
    },
  },
  actions: {},
  modules: {},
});
