<template>
	<el-dialog title="意见反馈" :visible.sync="opinionShow" append-to-body style="text-align: center;">
		<div style="display: flex;align-items: center;justify-content: space-around;">
			<div class="Nav_class_top_base">类型</div>
			<el-select style="width: 620px;" clearable filterable v-model="from.kind">
				<el-option v-for="item in suggest_kind" :key="item.id" :label="item.valueName" :value="item.valueCode" />
			</el-select>
		</div>
		<div style="display: flex;align-items: center;justify-content: space-around;margin: 20px 0;">
			<div class="Nav_class_top_base">标题</div>
			<el-input style="width: 620px;" v-model="from.title"/>
		</div>
		<div style="display: flex;align-items: center;justify-content: space-around;">
			<div class="Nav_class_top_base">内容</div>
			<quillEditor style="width: 620px;" v-model="from.content"></quillEditor>
		</div>
		<div style="margin-top: 20px;">
			<el-button @click="opinionShow=false">取消</el-button>
			<el-button @click="sugGestSave()">提交</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		suggestSave,
		queryListByDictValue
	} from '../../api/base.js'
	import baseJson from '../../assets/json/base.json'
	import {
		quillEditor
	} from "vue-quill-editor";
	export default {
		name: 'Study',
		data() {
			return {
				opinionShow:false,
				suggest_kind:null,
				options: JSON.parse(JSON.stringify(baseJson.options)),
				from:{
					kind:'suggestion',
					title:null,
					content:null,
					master:"xingzhengjin@youcun.tech",
				}
			}
		},
		components:{
			quillEditor
		},
		methods: {
			init(){
				this.opinionShow=true
				this.querylistbydictvalue('suggest_kind','suggest_kind')
			},
			querylistbydictvalue(n,val) {
				queryListByDictValue('dictValue=' + n).then(res => {
					this[val] = res.data.result
				})
			},
			sugGestSave(){
				suggestSave(this.from).then(res=>{
					this.$message("意见提交成功")
					this.opinionShow=false
				})
			},
		}
	}
</script>

<style>
</style>
