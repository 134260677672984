<template>
	<!-- 初始页面 -->
	<div id="app1">
		<el-container style="height: 100vh; border: 1px solid #eee;background-color: rgb(238, 241, 246)">
			<el-aside width="102px" style="background-color: #19409e;">
				<Left_nav ref="Left_nav"></Left_nav>
			</el-aside>
			<el-container>
				<el-header style="font-size: 12px">
					<Top_nav v-if="isRouterAlive"></Top_nav>
				</el-header>
				<el-main>
					<!-- <crumbs></crumbs> -->
					<router-view class="col-md-12 height85vh" />
				</el-main>
			</el-container>
		</el-container>
		<upload ref="upload"></upload>
	</div>
</template>
<script>
	import upload from '../../components/upload/index.vue'
	import Top_nav from '../../components/Top_nav.vue'
	// import crumbs from '../../components/crumbs/index.vue'
	import Left_nav from '../../components/Left_nav.vue'
	export default {
		name: 'App',
		data() {
			return {
				isRouterAlive: true
			}
		},
		components: {
			Top_nav,
			Left_nav,
			upload
		},
		mounted() {
			if(this.$store.state.largeFile){
				this.getLocalFile()
			}
		},
		watch: {
			$route(to, from) {
				if (to.path == '/study' || to.path == '/Study') {
					this.$refs.Left_nav.router_index()
				}
				if (to.path == '/explain' || to.path == '/Explain' || to.path == '/explain1' || to.path == '/Explain1' ||
					to.path == '/explain2' || to.path == '/Explain2' || to.path == '/explain3' || to.path == '/Explain3') {
					this.isRouterAlive = false
					this.$nextTick(() => (this.isRouterAlive = true))
				}
				if (from.path == '/explain' || from.path == '/Explain' || from.path == '/explain1' || from.path ==
					'/Explain1' || from.path == '/explain2' || from.path == '/Explain2' || from.path == '/explain3' || from
					.path == '/Explain3') {
					this.isRouterAlive = false
					this.$nextTick(() => (this.isRouterAlive = true))
				}
			}
		},
		methods: {
			async getLocalFile() {
				const dirHandle = await window.showDirectoryPicker()
				this.$store.commit('getDirHandle', dirHandle)
			},
			beiChildMethod() {
				alert('我是parent!')
			}
		}
	}
</script>
<style>
	body {
		background: #F3F5F9;
	}

	.el-header {
		padding: 0 !important;
	}

	.row,
	.clearfix {
		background-color: #FFFFFF;
	}

	.container {
		padding: 0;
		margin: 0;
	}

	.class_study_con_base {
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #071737;
		margin: 8px 0;
	}

	.class_study {
		overflow: auto;
	}

	.class_study_con {
		text-align: left;
		margin-top: 20px;
	}

	.explain_right {
		padding: 0 6px;
		height: 30px;
		border-radius: 2px;
		border: 1px solid #5D94E6;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		line-height: 26px;
		border-radius: 8px;
	}
</style>
