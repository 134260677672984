//下载处理zip
import { Message } from 'element-ui';
const DownloadProcessing = (data) => {
  let aLink = document.createElement('a');
  aLink.href = data;
  aLink.style.display = 'none';
  document.body.appendChild(aLink);
  // debugger;

  aLink.click();
  // debugger
  document.body.removeChild(aLink);
};
//切割字符串成数组 字符串 切割字段
const characterStringPartition = (String, attribute) => {
  let data = String.split(attribute);
  return data;
};
//替换①②③。。。 val 字符串
function textcircled(val) {
  if (val) {
    let a = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫'];
    let data = val.replace(/\\textcircled {(1)}/g, a[0]);
    data = data.replace(/\\textcircled {(2)}/g, a[1]);
    data = data.replace(/\\textcircled {(3)}/g, a[2]);
    data = data.replace(/\\textcircled {(4)}/g, a[3]);
    data = data.replace(/\\textcircled {(5)}/g, a[4]);
    data = data.replace(/\\textcircled {(6)}/g, a[5]);
    data = data.replace(/\\textcircled {(7)}/g, a[6]);
    data = data.replace(/\\textcircled {(8)}/g, a[7]);
    data = data.replace(/\\textcircled {(9)}/g, a[8]);
    data = data.replace(/\\textcircled {(10)}/g, a[9]);
    data = data.replace(/\\textcelsius/g, '度');
    data = data.replace(/&amp;nbsp;/g, ' ');
    return data;
  }
}
// 适配器  getitemsforplan 换成 gotUserJoinAllStation
// 输入 getitemsforplan 请求返回参数 gotUserJoinAllStation 请求返回参数
// 输出 getitemsforplan重新改变参数
function Adapter1(canshu, data) {
  canshu.forEach((item) => {
    data.forEach((items) => {
      if (item.childrenList && item.childrenList.length > 0) {
        Adapter1(item.childrenList, data);
      } else {
        if (item.id == items.subId) {
          item.questionOutVo.index = item.questionOutVo.index
            ? item.questionOutVo.index
            : items.queIndex;
          item.right_students = items.rightUserNameList;
          item.wrong_students = items.errorUserNameList;
          item.right_rate = items.passRate * 100;
          item.submit_student_number = items.answerUserCount;
        }
      }
    });
  });
  return canshu;
}

function Adapter3(canshu, data) {
  canshu.forEach((item) => {
    data.forEach((items) => {
      if (item.childrenList && item.childrenList.length > 0) {
        Adapter3(item.childrenList, data);
      } else {
        if (item.id == items.subId) {
          item.right_students = items.rightUserNameList;
          item.wrong_students = items.errorUserNameList;
          item.right_rate =
            (items.rightUserNameList.length / items.joinUserNameList.length) *
            100;
          item.right_rate = item.right_rate ? item.right_rate + '%' : '0.0%';
          item.submit_rate =
            (items.joinUserNameList.length / items.planUserCount) * 100;
          item.submit_rate = item.submit_rate ? item.submit_rate + '%' : '0.0%';
          item.submit_student_number = items.answerUserCount;
        }
      }
    });
  });
  return canshu;
}
//处理批改结果重复

function recombination(arr) {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i].serial == arr[j].serial) {
        arr.splice(j, 1);
        j--;
      }
    }
  }
  return arr;
}
function recombinationCanshu(arr, attribute) {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i][attribute] == arr[j][attribute]) {
        arr.splice(j, 1);
        j--;
      }
    }
  }
  return arr;
}
//处理批改结果重复 上一级
function recombinationParent(baseTopicInformation) {
  baseTopicInformation.forEach((item) => {
    if (item.childrenList && item.childrenList.length > 0) {
      recombinationParent(item.childrenList);
    } else {
      if (item.answerAreaList.length > 1) {
        item.answerAreaList = recombination(item.answerAreaList);
      }
    }
  });
  return baseTopicInformation;
}

function findParentsId(treeData, id) {
  if (treeData.length == 0) return;
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].id == id) {
      return [];
    } else {
      if (treeData[i].children) {
        let res = findParentsId(treeData[i].children, id);
        if (res !== undefined) {
          return res.concat(treeData[i].id).reverse();
        }
      }
    }
  }
}

// 组织结构子id，找父id
export const orgChildIdFindParent = (childId, orgList) => {
  const result = findParentsId(orgList, childId);
  return result || [];
};
function findParentsIds(treeData, id) {
  if (treeData.length == 0) return;
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].id == id) {
      return [];
    } else {
      if (treeData[i].childrenList) {
        let res = findParentsIds(treeData[i].childrenList, id);
        if (res !== undefined) {
          return res.concat(treeData[i].id).reverse();
        }
      }
    }
  }
}

// 组织结构子id，找父id
export const orgChildIdFindParents = (childId, orgList) => {
  const result = findParentsIds(orgList, childId);
  return result || [];
};

// 根据id 找题目
function IdFindQuestions(id, orglis) {
  if (orglis.length == 0) return;
  for (let i = 0; i < orglis.length; i++) {
    if (orglis[i].answerAreaList && orglis[i].answerAreaList.length > 0) {
      for (let j = 0; j < orglis[i].answerAreaList.length; j++) {
        if (orglis[i].answerAreaList[j].id == id) {
          return [orglis[i]];
        }
      }
    } else {
      if (orglis[i].childrenList) {
        let res = IdFindQuestions(id, orglis[i].childrenList);
        if (res !== undefined) {
          return res.concat(orglis[i]).reverse();
        }
      }
    }
  }
}
// 组织结构子id，找父id
export const IdFindQuestionFun = (id, orglis) => {
  const result = IdFindQuestions(id, orglis);
  return result || [];
};

function IdFindQuestion(id, orglis) {
  let data = IdFindQuestionFun(id, orglis);
  if (data && data.length > 1) {
    return [data[1]];
  } else {
    return data;
  }
}
// 适配器  gotUserJoinAllStation 换成 getitemsforplan
// 输入 gotUserJoinAllStation 请求返回参数
// 输出 getitemsforplan参数
function Adapter2(canshu) {
  let dataArry = [];
  canshu.forEach((item) => {
    let data = {
      id: null,
      questionOutVo: {
        index: null,
        featureTypeName: null,
        content: null,
      },
    };
    data.id = item.subId;
    data.questionOutVo.index = item.queIndex;
    data.questionOutVo.content = item.title;
    data.questionOutVo.featureTypeName = item.featureTypeName;
    dataArry.push(data);
  });
  return dataArry;
}
//根据对象一个属性返回数组中一个对象
function PropertyReturnObject(arry, attribute, value) {
  let canshu = arry.filter((item, index) => {
    return item[attribute] == value;
  });
  return canshu;
}
// 参数
function CorrectionResults(baseTopicInformation, CorrectionResultsData) {
  baseTopicInformation.forEach((items) => {
    if (items.childrenList && items.childrenList.length > 0) {
      CorrectionResults(items.childrenList, CorrectionResultsData);
    } else {
      CorrectionResultsData.forEach((CorrectionResultsItem) => {
        if (CorrectionResultsItem.sub_id == items.id) {
          items.answerAreaList.forEach((itemSerial) => {
            if (itemSerial.serialAnswer == CorrectionResultsItem.serial) {
              itemSerial.result =
                CorrectionResultsItem.manual_result === 0 ||
                CorrectionResultsItem.manual_result
                  ? CorrectionResultsItem.manual_result
                  : CorrectionResultsItem.auto_result;
              itemSerial.correct_score = CorrectionResultsItem.score;
            }
          });
        }
      });
    }
  });
  return baseTopicInformation;
}
// 处理数据
var data = {
  baseTopicInformation: null,
  CorrectionResultsItem: [],
};

function CorrectionResultsn(
  baseTopicInformation,
  CorrectionResultsData,
  stutes
) {
  if (!stutes) {
    data.baseTopicInformation = null;
    data.CorrectionResultsItem = [];
  }
  baseTopicInformation.forEach((items) => {
    if (items.childrenList && items.childrenList.length > 0) {
      CorrectionResultsn(items.childrenList, CorrectionResultsData, 'true');
    } else {
      CorrectionResultsData.forEach((CorrectionResultsItem) => {
        if (CorrectionResultsItem.questionId == items.id) {
          items.answerAreaList.forEach((itemSerial) => {
            if (itemSerial.serialAnswer == CorrectionResultsItem.serial) {
              data.CorrectionResultsItem.push(itemSerial);
              itemSerial.result = CorrectionResultsItem.result;
              itemSerial.correct_score = CorrectionResultsItem.score;
            }
          });
        }
      });
    }
  });
  data.baseTopicInformation = baseTopicInformation;
  return data;
}
// 使用正则进行报错格式和特殊字符的替换
function handleSpecialCharacters(jsonStr, index) {
  if (index == 66) {
    console.log(jsonStr);
  }
  //{"contentType":"Text","language":"zh_CN","customGrammarId":null,"customResources":"zHbushouhuizong","customContent":"zHbushouhuizong"}
  //{"contentType":"Text","language":"zh_CN","customGrammarId":null,"customResources":"zHbiaodianfuhaohuizong","customContent":"。 ？ ！ ， 、 ； ： \" \" ( ) —— …… 《 》 ____ — • · "}
  let obj = {};
  //jsonStr 存在并 判断jsonStr是否为字符串
  if (
    jsonStr &&
    Object.prototype.toString.call(jsonStr) == '[object String]' &&
    jsonStr != 'null'
  ) {
    jsonStr = jsonStr.replace(/\r/g, '\\r');
    jsonStr = jsonStr.replace(/\n/g, ' ');
    jsonStr = jsonStr.replace(/\t/g, '\\t');
    //23年4月23  就改了这一行代码
    jsonStr = jsonStr.replace(/\\\"/g, '&#34;');
    //
    jsonStr = jsonStr.replace(/\\/g, '\\\\');
    jsonStr = jsonStr.replace(/\'/g, '&#39;');
    jsonStr = jsonStr.replace(/</g, '$lt;');
    jsonStr = jsonStr.replace(/>/g, '$gt;');
    obj = JSON.parse(jsonStr);
  }
  return jsonStr;
}

// 地址url 处理
function queryURLParams(url) {
  let result = {};
  let reg1 = /([^?=&#]+)=([^?=&#]+)/g;
  let reg2 = /#([^?=&#]+)/g;
  url.replace(reg1, (n, x, y) => (result[x] = y));
  url.replace(reg2, (n, x) => (result['HASH'] = x));
  return result;
}
//登录设置 response对象 response.loginData返回参数 。data.token 清除token
function SignIn(response) {
  console.log('response', response.loginData.data);
  if (
    response.loginData.data &&
    (response.loginData.data.code == '6666' ||
      response.loginData.data.code == '0001' ||
      response.loginData.data.code == '0002')
  ) {
    localStorage.removeItem(response.token);
    location.reload();
  } else if (response.loginData.data.code == '0000') {
    return response.loginData;
  } else {
    if (response.loginData.data.code) {
      Message({
        message: response.loginData.data.msg,
        type: 'error',
        duration: 5 * 1000,
      });
    } else {
      return response.loginData;
    }
  }
}
export default {
  DownloadProcessing,
  characterStringPartition,
  PropertyReturnObject,
  textcircled,
  Adapter1,
  Adapter2,
  Adapter3,
  orgChildIdFindParent,
  CorrectionResults,
  queryURLParams,
  CorrectionResultsn,
  SignIn,
  recombinationParent,
  orgChildIdFindParents,
  recombination,
  IdFindQuestion,
  IdFindQuestions,
  handleSpecialCharacters,
  recombinationCanshu,
};
