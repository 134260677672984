<template>
	<div>
		<el-select clearable filterable v-model="teachlanguages" :placeholder="$store.state.teachlanguagedata.select" >
			<el-option v-for="item in ['汉语','English']" :key="item" :label="item" :value="item">
			</el-option>
		</el-select>
	</div>
</template>

<script>
	import {
		changeLanguage
	} from '../api/base.js'
	import teachlanguagechian from '../assets/json/chian.json'
	import teachlanguageenglish from '../assets/json/english.json'
	export default {
		name: 'Study',
		data() {
			return {
				teachlanguages:localStorage.getItem("teachlanguage")?JSON.parse(localStorage.getItem("teachlanguage")):'汉语',
			}
		},
		props: ['LanguagesData'],
		watch:{
			teachlanguages(val){
				if(val=='汉语'){
					localStorage.setItem("teachlanguage",JSON.stringify(val))
					this.$store.commit('teachlanguagedatashow',teachlanguagechian)
					let data={
						language:'汉语',
						id:this.LanguagesData,
					}
					this.ChangeLanguage(data)
				}else{
					localStorage.setItem("teachlanguage",JSON.stringify(val))
					this.$store.commit('teachlanguagedatashow',teachlanguageenglish)
					let data={
						language:'English',
						id:this.LanguagesData,
					}
					this.ChangeLanguage(data)
				}
			},
		},
		mounted() {
			if(this.teachlanguages=='汉语'){
				this.$store.state.teachlanguagedata=teachlanguagechian
			}else{
				this.$store.state.teachlanguagedata=teachlanguageenglish
			}
		},
		methods: {
			ChangeLanguage(data){
				changeLanguage(data).then(res=>{
				})
			},
		}
	}
</script>

<style>
</style>